<template>
    <content-wrapper>
        <w-data-table
            :headers="headers"
            itemKey="uuid"
            :items="items"
        >
            <template #actions>
                <w-btn-action
                    :text="$trans('Add')"
                    icon="ADD"
                    @click="onShowForm"
                />
            </template>

            <template #item.label="{ item }">
                {{ item.values.label }}
            </template>

            <template #item.begin="{ item }">
                {{ item.values.begin }}
            </template>

            <template #item.end="{ item }">
                {{ item.values.end }}
            </template>

            <template #item.actions="{ item }">
                <div class="d-flex justify-end">
                    <v-btn
                        icon
                        color="success"
                        @click="onShowForm(item)"
                    >
                        <w-icon size="20" value="mdi-pencil"/>
                    </v-btn>
                    <v-btn
                        icon
                        color="error"
                        @click="onDelete(null, item)"
                    >
                        <w-icon size="20" value="mdi-delete"/>
                    </v-btn>
                </div>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isDrawer"
            :title="$trans('Study period')"
            :loading="loading"
        >
            <div class="pa-4">
                <w-simple-form
                    :key="'groupForm' + formKey"
                    :fields="formFields"
                    :item="item.values"
                    :errors="errors"
                    deletable
                    :disabled="loading"
                    @save="onStore"
                    @update="onUpdate"
                    @delete="onDelete"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import { required } from '@/utils/validation'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { deleteStudyPeriod, storeStudyPeriod, updateStudyPeriod } from '@apps/school/api/studyPeriods'

export default {
    name: 'StudyPeriodsManage',
    components: { ContentWrapper },
    computed: {
        headers() {
            return [
                {
                    text: this.$trans('Label'),
                    value: 'label'
                },
                {
                    text: this.$trans('Begin'),
                    value: 'begin'
                },
                {
                    text: this.$trans('End'),
                    value: 'end'
                },
                {
                    align: 'right',
                    value: 'actions'
                }
            ]
        },
        formFields() {
            return [
                {
                    name: 'label',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Label'),
                        outlined: true,
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    type: 'w-date-input',
                    name: 'begin',
                    props: {
                        label: this.$trans('Begin'),
                        outlined: true,
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    type: 'w-date-input',
                    name: 'end',
                    props: {
                        label: this.$trans('End'),
                        outlined: true,
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                },
            ]
        },
        items() {
            return this.$store.getters['school/studyPeriods']
        }
    },
    data() {
        return {
            loading: false,
            errors: {},
            isDrawer: false,
            formKey: 0,
            item: {},
            filterByLevel: null,
            draggedData: null
        }
    },
    methods: {
        onShowForm(item) {
            this.item = item || {}
            this.formKey++
            this.isDrawer = true
        },
        async fetchStudyPeriods() {
            this.$lprogress.begin()

            try {
                await this.$store.dispatch('school/fetchStudyPeriods')
            } finally {
                this.$lprogress.end()
            }
        },
        async onStore(data) {
            this.loading = true
            try {
                await storeStudyPeriod(data)
                this.isDrawer = false
                this.loading = false
                await this.fetchStudyPeriods()
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.loading = false
            }
        },
        async onUpdate(data) {
            this.loading = true
            try {
                await updateStudyPeriod(this.item.uuid, data)
                await this.fetchStudyPeriods()
                this.isDrawer = false
                this.loading = false
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.loading = false
            }
        },
        async onDelete(data, item) {
            this.isDrawer = false

            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${this.$trans('Delete')} ${ data ? data.label : item.values.label }`
            }))

            if (!isConfirm) {
                return
            }

            this.$lprogress.begin()

            try {
                await deleteStudyPeriod(data ? this.item.uuid : item.uuid)
                await this.fetchStudyPeriods()
                this.$lprogress.end()
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
