var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-wrapper',[_c('w-data-table',{attrs:{"headers":_vm.headers,"itemKey":"uuid","items":_vm.items},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('w-btn-action',{attrs:{"text":_vm.$trans('Add'),"icon":"ADD"},on:{"click":_vm.onShowForm}})]},proxy:true},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.values.label)+" ")]}},{key:"item.begin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.values.begin)+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.values.end)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.onShowForm(item)}}},[_c('w-icon',{attrs:{"size":"20","value":"mdi-pencil"}})],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.onDelete(null, item)}}},[_c('w-icon',{attrs:{"size":"20","value":"mdi-delete"}})],1)],1)]}}])}),_c('w-aside-drawer',{attrs:{"title":_vm.$trans('Study period'),"loading":_vm.loading},model:{value:(_vm.isDrawer),callback:function ($$v) {_vm.isDrawer=$$v},expression:"isDrawer"}},[_c('div',{staticClass:"pa-4"},[_c('w-simple-form',{key:'groupForm' + _vm.formKey,attrs:{"fields":_vm.formFields,"item":_vm.item.values,"errors":_vm.errors,"deletable":"","disabled":_vm.loading},on:{"save":_vm.onStore,"update":_vm.onUpdate,"delete":_vm.onDelete}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }